/*----------  Global Variables  ----------*/

let wWidth;
let wHeight;

/*----------  Document Ready  ----------*/

$(document).ready(() => {
	wWidth = $(window).width();
	wHeight = $(window).height();

    var lastScrollTop = 0;
	$(window).scroll(function() {
			if($(this).scrollTop() > 0 && !$('header').hasClass('navbar-fixed')) {
				$('header').addClass('navbar-fixed');
			}
			if($(this).scrollTop() <= 0) {
				$('header').removeClass('navbar-fixed');
			}

			var st = $(this).scrollTop();
			if($('header').hasClass('navbar-fixed') && !$('body').hasClass('overflow-hidden')) {
                
                var ua = navigator.userAgent.toLowerCase(); 
                if (ua.indexOf('safari') != -1) { 
                    if (ua.indexOf('chrome') > -1) {
                 
                        if (st > lastScrollTop){
                            $('header').css("transform", "translateY(-100%)");
                        } else {
                           $('header').css("transform", "translateY(0%)");
                       }
                 
                    } else {
                        
                    }
                }
				
			}
			lastScrollTop = st;
	});

	initSmoothScroll();
	fn_nav_toggle();
    initWow();
    initAos();

	console.log('Ready!');
});

/*----------  Window Events  ----------*/

$(window).on('load resize', () => {
	wWidth = $(window).width();
	wHeight = $(window).height();
});

function initSmoothScroll() {
    $('.smooth-scroll').on('click', function (event) {
        event.preventDefault();
        $('body, html').animate({
            scrollTop: $($(this).attr('href')).offset().top
        }, 1000);
    });

    $('.smoothScroll').on('click', function (event) {
        event.preventDefault();
        $('body, html').animate({
            scrollTop: $($(this).data('scrolltarget')).offset().top
        }, 1000);
    });
}

function initAos(){
    AOS.init();
}

function initWow() {
    new WOW().init();
}

function initButter() {
    // Scrollbar.initAll();
    var ios = iOS();
    console.log(ios);
    
    if(ios == false){
       
        if(wWidth > 991 && $('#butterWrapper').length) {
            butter.init({
                wrapperId: 'butterWrapper',
                wrapperDamper:0.1
            });
        }    
    }    
}

function fn_nav_toggle() {
    var target;

    var $toggler = $('[data-nav-toggle]');
    
    $(window).on('load resize', function(){
        
        if ($toggler.length > 0 && $(this).width() < 768) {
            
            $toggler.off('click.toggle').on('click.toggle', function () {
                target = $(this).data('nav-toggle');
                var $target = $(target).first();
    
                if ($target.length > 0) {
                    if ($target.hasClass('show')) {
                        $target.removeClass('show');
                        $target.find('.nav-item').removeClass('animate__animated animate__fadeInUp');
                        $('body').css('overflow', 'auto');
                        $toggler.removeClass('open');
                    }
                    else {
                        $target.addClass('show');
                        $target.find('.nav-item').addClass('animate__animated animate__fadeInUp');
                        $('body').css('overflow', 'hidden');
                        $toggler.addClass('open');
                    }
                }
    
                return false;
            });
        }   
    });
}

